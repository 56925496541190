import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { Message, Error, FluidLoginContainer, ImageContainer, StyledImage } from './LoginAskPassword.styled'
import { User } from '../../context/userContext';
import { useNavigate } from 'react-router';
import Countdown from 'react-countdown';

const LoginAskPassword = () => {
    let [login, setLogin] = useState('');
    let [show, setShow] = useState(true);
    const navigation = useNavigate();

    let [error, setError] = useState({
        active: false, 
        message : ''
    });

    let [message, setMessage] = useState({
        active: false, 
        message : ''
    });

    
    const fetchGeneratePassword = () => {
        return fetch(`${process.env.REACT_APP_HOST}/api/password_reset/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: login}),
        })
        .then(response => {
            if(response.ok){
                setShow(false);
                setMessage({active: true, message: 'Wysyłanie wiadomości email. Za 5 sekund zostaniesz przekierowany na stronę generowania hasła.'})
                return response.json();
            }

            return Promise.reject(response);
        }).catch((error) => {
            console.log(error);
            setError({active: true, message: 'Podałeś nieprawidłowy email lub wystąpił wewnętrzny błąd.'})
        })
    }


    const handleGeneratePassword = async (event) => {
        fetchGeneratePassword();
        event.preventDefault();
    }


    const handleLoginChange = (e) => {
        resetError();
        resetMessage();
        setLogin(e.target.value ? e.target.value : '');
    }
    
    const resetError = () => {
        setError({active: false, message: ''})
    }

    const resetMessage = () => {
        setMessage({active: false, message: ''})
    }


    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          navigation("/potwierdz_haslo")
        } else {
          return <span> {seconds} </span>;
        }
      };

    useEffect(() => {

    }, [])

    return (
        <FluidLoginContainer fluid>
            <Container fluid="md">
                <Row className='row justify-content-sm-center h-100'>
                    <Col xxl={4} lg={5} md={6} sm={8} xs={12} className="">
                        <ImageContainer>
                            <StyledImage src="./logo.png" alt="logo" />
                        </ImageContainer>
                        <Card className='shadow-lg'>
                            <Card.Body className='p-4'>
                                <Card.Title className='mb-4'>
                                Generowanie hasła
                                </Card.Title>
                                {show &&
                                <Form onSubmit={handleGeneratePassword}>
                                    <Form.Group className="mb-3" controlId="email" id="password_group">
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control type="email" onChange={handleLoginChange} value={login} required/>
                                    </Form.Group>
                                    <Error>{error.active ? error.message : ''}</Error>
                                    <Message>{message.active ? message.message : ''}</Message>
                                    <button className='btn btn-primary w-100 my-2' type='submit'>Wyślij</button>
                                    {/* <button className='btn btn-secondary w-100 my-2' onClick={event =>  window.location.href='/potwierdz_haslo'} type='submit'>potwierdz_haslo</button> */}
                                </Form>
                                }
                                {!show &&
                                <Form>
                                    <Message>{message.active ? message.message : ''}</Message>
                              
                                    <button className='btn btn-secondary w-100 my-2' onClick={event =>  window.location.href='/potwierdz_haslo'} type='submit'>
                                        Logowanie        
                                        <Countdown date={Date.now() + 5000} renderer={renderer}/> 
                                    </button>
                                </Form>

}

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </FluidLoginContainer>
    )
}

export default LoginAskPassword