import React from 'react'
import AddFileModal from './AddModal/AddFileModal'
import moment from 'moment'

const Files = (props) => {
    const formatDate = (date) => {
        if (!date)
            return '-'
        return moment(date).format('DD.MM.YYYY HH:mm');
    }

    const downloadFile = (id, fileName) => {
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_HOST}${fileName}`;
        link.setAttribute("download", "filename");
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
    }

    const seeFile = (id, fileName) => {
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_HOST}${fileName}`;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
    }

    const seeFileUsingGoogle = (id, fileName) => {
        const link = document.createElement("a");
        link.href = `https://docs.google.com/viewer?url=${process.env.REACT_APP_HOST}${fileName}`;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
    }


    const filename = (name) => {
        if (!name) return "-";

        return name.split("/").pop()
    }

    return (
        <>   {props?.files[0] &&

            <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Data:</th>
                        <th>Nazwa pliku:</th>
                        <th>Opis:</th>
                        <th>Akcje:</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props?.files?.map((file, index) => {
                        return (
                            <tr key={index}>
                                <td>{formatDate(file.uploaded_at)}</td>
                                <td className='text-break'>{filename(file.document)}</td>
                                <td className='hideOnMobile'>{file.description}</td>
                                <td className="text-center">
                                    <i className="bi bi-eye text-primary pointer m-2"
                                       onClick={() => downloadFile(file.id, file.document)}></i>
                                    <i className="bi bi-download text-primary pointer m-2"
                                       onClick={() => seeFile(file.id, file.document)}></i>
                                    <i className="bi bi-google text-primary pointer m-2"
                                       onClick={() => seeFileUsingGoogle(file.id, file.document)}></i>
                                </td>
                            </tr>
                        )
                    })}

                    </tbody>
                </table>
            </div>
        }
            {!props?.files[0] && "brak."}

            {props.showAddFileModal &&
                <AddFileModal modalShow={props.showAddFileModal} setModalShow={props.setShowAddFileModal}
                              handleOnUpload={props.handleOnUpload}/>}
        </>
    )
}

export default Files