import React, {useContext, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap';
import {User} from '../../../../context/userContext';
import moment from 'moment';

const AddTaskModal = (props) => {
    const [text, setText] = useState('');
    const [description, setDescription] = useState('');
    const [dueDate, setDueDate] = useState(moment().add(1, 'months').format('YYYY-MM-DD'));

    const [user] = useContext(User);

    const handleOpen = () => {
        props.setModalShow(true);
    }

    const handleClose = () => {
        props.setModalShow(false);
    }

    const handleNameChange = (event) => {
        setText(event.target.value ?? '');
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value ?? '');
    }

    const handleDueDateChange = (event) => {
        setDueDate(event.target.value ? moment(event.target.value).format('YYYY-MM-DD') : moment().add(1, 'months').format('YYYY-MM-DD'));
    }


    const example_text =
        `Lab 1. Przygotowanie środowiska, 2023-05-13
Lab 2. Przetwarzania danych,  2023-05-20
Lab 3. Zbudowanie hurtowni danych, 2023-06-03
Lab 4. Bezpieczeństwo 1:  Ataki / Ochrona, 2023-06-10
Lab 5. Bezpieczeństwo 2: Ataki / Ochrona, 2023-06-17`

    const handleAddTaskRequest = async () => {
        let text2 = text.replace(/(?:\r\n|\r|\n)/g, ";")
        await fetch(`${process.env.REACT_APP_HOST}/api/text_to_tasks/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lecture: props.lectureId,
                text: text2,
            })
        }).then(response => response.json()).then(data => {
            console.log(data);
            props.fetchLecture();
            props.fetchTasks();
            props.setModalShow(false);
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas dodawania zadania!');
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        handleAddTaskRequest();
    }

    const isActive = props.modalShow;

    return (
        <Modal show={isActive} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="bi bi-journal-plus"></i> {props.modalName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Treść:

                            <p><cite className='text-dark text-muted'>``nazwa, deadline</cite><br></br>
                                <cite className='text-dark text-muted'>nazwa, deadline</cite><br></br>
                                <cite className='text-dark text-muted'>nazwa, deadline``</cite></p>

                        </Form.Label>
                        <Form.Control as="textarea" rows={10} placeholder={example_text} onChange={handleNameChange}
                                      value={text} required/>
                    </Form.Group>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zamknij
                    </Button>
                    <Button variant="primary" type="submit">
                        Dodaj
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddTaskModal