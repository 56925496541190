import styled from "styled-components";


export const UserWrapper = styled.div`
    margin-bottom: 40px;
`

export const Username = styled.div`
    margin-bottom: 20px;


`