import BasicContainer from "../components/BasicContainer/BasicContainer";
import Lectures from "../components/Lectrures/Lectures";
import SingleLecture from "../components/Lectrures/SingleLecture/SingleLecture";
import Login from "../components/Login/Login";
import LoginAskPassword from "../components/LoginAskPassword/LoginAskPassword";
import LoginPasswordConfirm from "../components/LoginPasswordConfirm/LoginPasswordConfirm";

import Main from "../components/Main/Main";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import Raports from "../components/Reports/Raports";
import Specfic from "../components/Reports/Specific/Specfic";
import Students from "../components/Students/Students";
import Settings from "../components/Settings/Settings";

import Logout from "../components/Logout/Logout";

export const routes = [
    {
        name: 'Login',
        path: '/login',
        icon: 'bi bi-house-door-fill',
        nav: false,
        component: <Login/>,
    },
    {
        name: 'Hasło',
        path: '/haslo',
        nav: false,
        component: <LoginAskPassword/>,
    },
    {
        name: 'Potwierdz hasło',
        path: '/potwierdz_haslo',
        nav: false,
        component: <LoginPasswordConfirm/>,
    },
    {
        name: 'Strona głowna',
        path: '/',
        icon: 'bi bi-house-door-fill',
        nav: true,
        component:
            <ProtectedRoute>
                <BasicContainer>
                    <Main/>
                </BasicContainer>
            </ProtectedRoute>
    },
    {
        name: 'Sprawozdania',
        path: '/sprawozdania',
        icon: 'bi bi-journal-text',
        adminVisibility: false,
        nav: true,
        component:
            <ProtectedRoute>
                <BasicContainer>
                    <Raports/>
                </BasicContainer>
            </ProtectedRoute>
    },
    {
        name: 'Sprawozdania',
        path: '/sprawozdania/:id',
        nav: false,
        component:
            <ProtectedRoute>
                <BasicContainer>
                    <Specfic/>
                </BasicContainer>
            </ProtectedRoute>
    },
    {
        name: 'Zajęcia',
        path: '/zajecia',
        icon: 'bi bi-journal-bookmark-fill',
        nav: true,
        component:
            <ProtectedRoute>
                <BasicContainer>
                    <Lectures/>
                </BasicContainer>
            </ProtectedRoute>
    },
    {
        name: 'Zajęcia',
        path: '/zajecia/:id',
        nav: false,
        component:
            <ProtectedRoute>
                <BasicContainer>
                    <SingleLecture/>
                </BasicContainer>
            </ProtectedRoute>
    },
    {
        name: 'Studenci',
        path: '/studenci',
        icon: 'bi bi-people-fill',
        studentVisibility: false,
        nav: true,
        component:
            <ProtectedRoute>
                <BasicContainer>
                    <Students/>
                </BasicContainer>
            </ProtectedRoute>
    },
    {
        name: 'Ustawienia',
        path: '/ustawienia',
        icon: 'bi bi-gear-fill',
        studentVisibility: false,
        nav: true,
        component:
            <ProtectedRoute>
                <BasicContainer>
                    <Settings/>
                </BasicContainer>
            </ProtectedRoute>
    },
    {
        name: 'Wyloguj',
        path: '/logout',
        icon: 'bi bi-box-arrow-right',
        studentVisibility: true,
        nav: true,
        component: <Logout/>

    }
];
