import React, {useContext, useState} from 'react'
import {Button} from 'react-bootstrap';
import {User} from '../../../../context/userContext';
import {useParams} from 'react-router';


const QuickRate = (props) => {
    const [error, setError] = useState(null);
    const params = useParams();
    const [user] = useContext(User);

    const handleSendGrade = (event) => {
        event.preventDefault();
        console.log("props");
        console.log(props);
        const grade = event.target.value;

        fetch(`${process.env.REACT_APP_HOST}/api/reports/${params.id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                grade: grade,
            })
        })
            .then(response => response.json())
            .then(data => {
                props.updateGrade(grade);

            })
            .catch(error => {
                alert("Ocena nie została zmienona. Błąd!")
                console.log(error);
            });
    };

    return (

        <div className="btn-group-responsive my-3 d-flex flex-wrap justify-content-end">
            <Button variant="danger m-1" value="2.0" onClick={handleSendGrade}>2.0</Button>
            <Button variant="warning m-1" value="3.0" onClick={handleSendGrade}>3.0</Button>
            <Button variant="secondary m-1" value="3.5" onClick={handleSendGrade}>3.5</Button>
            <Button variant="info m-1" value="4.0" onClick={handleSendGrade}>4.0</Button>
            <Button variant="primary m-1" value="4.5" onClick={handleSendGrade}>4.5</Button>
            <Button variant="success m-1" value="5.0" onClick={handleSendGrade}>5.0</Button>
        </div>
    )
}

export default QuickRate