import React, {useContext, useState} from 'react';
import {User} from '../../context/userContext';
import {useNavigate} from 'react-router-dom';
import {routes} from '../../routes/Routes';
import useAdmin from '../../hooks/useAdmin';
import {
    Hamburger,
    HamburgerLine,
    Items,
    Logo,
    LogoutContainer,
    MobileItems,
    Nav,
    NavBar,
    NavLink
} from './Navigation.styled';
import {Col, Container} from 'react-bootstrap';

const Navigation = () => {
    const [user, setUser, logout] = useContext(User); // Pobieramy funkcję logout
    const [menuActive, setMenuActive] = useState(false);
    const navigate = useNavigate();
    const isTeacher = useAdmin();

    const handleLogout = (event) => {
        event.preventDefault();
        logout();  // Używamy funkcji logout z kontekstu
        navigate('/login');
    };

    const handleOnLinkClick = () => {
        setMenuActive(false);
    };

    const generateMenu = () => {
        return routes.map((route, index) => {
            if (!user.logged) return "";
            if (route.adminVisibility === false && isTeacher) return "";

            if (route.studentVisibility === false && !isTeacher) return "";

            return route.nav ? (
                <NavLink key={index} icon={route.icon} to={route.path}
                         onClick={handleOnLinkClick}>
                    {route.name}
                </NavLink>
            ) : null;
        });
    };

    const handleLogoClick = () => {
        setMenuActive(false);
        navigate('/');
    };

    const toggleMenu = () => {
        setMenuActive((prev) => !prev);
    };

    return (
        <NavBar>
            <Col>
                <Container>
                    <Nav>
                        <Logo className="pointer" src="/logo_white.png" onClick={handleLogoClick}/>
                        <Items className="d-none d-md-flex">
                            {generateMenu()}
                        </Items>
                        <LogoutContainer>
                            <Hamburger onClick={toggleMenu} className='flex-shrink-0'>
                                <HamburgerLine/>
                                <HamburgerLine/>
                                <HamburgerLine/>
                            </Hamburger>
                        </LogoutContainer>

                        <MobileItems className="d-flex d-md-none" active={menuActive ? 1 : 0}>
                            {generateMenu()}
                        </MobileItems>
                    </Nav>
                </Container>
            </Col>
        </NavBar>
    );
};

export default Navigation;
