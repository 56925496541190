import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {User} from '../../context/userContext';

const Logout = () => {
    const [, , logout] = useContext(User); // Pobieramy funkcję logout z kontekstu
    const navigate = useNavigate();

    useEffect(() => {
        logout();  // Wywołujemy funkcję logout
        navigate('/login');  // Przekierowanie na stronę logowania
    }, [logout, navigate]);

    return null; // Nie renderujemy nic, tylko wylogowujemy i przekierowujemy
};

export default Logout;
