import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { Message, Errors, FluidLoginContainer, ImageContainer, StyledImage } from './LoginPasswordConfirm.styled'
import { User } from '../../context/userContext';
import { useNavigate } from 'react-router';
import Countdown from 'react-countdown';

const LoginPasswordConfirm = () => {
    let [token, setToken] = useState('');
    let [password, setPassword] = useState('');

    let [show, setShow] = useState(true);
    const navigation = useNavigate();

    let [errors, setErrors] = useState({
        active: false, 
        message : ''
    });

    let [message, setMessage] = useState({
        active: false, 
        message : ''
    });

    
    const fetchUpdatePassword = () => {
        return fetch(`${process.env.REACT_APP_HOST}/api/password_reset/confirm/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({token: token, password: password}),
        })

            .then(response => {
                if (response.ok) {
                    setShow(false);
                    setMessage({
                        active: true,
                        message: 'Sukces. Za 5 sekund nastąpi przekierowanie.'
                    });
                    return response.json();
                }
                return Promise.reject(response);
            })
            .then(data => {
                console.log('Data from server:', data);
            })
            .catch((error) => {

            error.json().then(json_data => {
                    // Tutaj możesz pracować z danymi błędów w formacie JSON
                console.log('Error JSON Data:', json_data);
                const errorFields = Object.keys(json_data);
                console.log("errorFields:");
                console.log(errorFields);


                setMessage({
                  active: true,
                  message: (
                    <div>
                      <p>Wystąpiły błędy przy generowaniu klucza resetującego:</p>
                      <ul>
                        {errorFields.map((field) => (
                          <li key={field}>
                            {Array.isArray(json_data[field]) ? (
                                <ul>{json_data[field].map((err, index) => (
                                            <li key={index}>{err}</li>
                                        ))}
                                </ul>
                            ) : typeof json_data[field] === 'string' ? (
                              <p>{json_data[field]}</p>
                            ) : (
                              <p>Błąd nieznany.</p>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ),
                });
                });
            });
    }

    const handleGeneratePassword = async (event) => {
        fetchUpdatePassword();
        event.preventDefault();
    }

    const handleTokenChange = (e) => {
        resetErrors();
        resetMessage();
        setToken(e.target.value ? e.target.value : '');
    }

    const handlePasswordChange = (e) => {
        resetErrors();
        resetMessage();
        setPassword(e.target.value ? e.target.value : '');
    }
    
    const resetErrors = () => {
        setErrors({active: false, message: ''})
    }

    const resetMessage = () => {
        setMessage({active: false, message: ''})
    }


    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          navigation("/login")
        } else {
          // Render a countdown
          return <span> {seconds} </span>;
        }
      };

    useEffect(() => {

    }, [])

    return (
        <FluidLoginContainer fluid>
            <Container fluid="md">
                <Row className='row justify-content-sm-center h-100'>
                    <Col xxl={4} lg={5} md={6} sm={8} xs={12} className="">
                        <ImageContainer>
                            <StyledImage src="./logo.png" alt="logo" />
                        </ImageContainer>
                        <Card className='shadow-lg'>
                            <Card.Body className='p-4'>
                                    <Card.Title className='mb-4'>
                                    Generowanie hasła
                                    </Card.Title>
                                    {show &&
                                    <Form onSubmit={handleGeneratePassword}>
                                        <Form.Group className="mb-3" controlId="text" id="token_group">
                                            <Form.Label>Token (z wiadomości email):</Form.Label>
                                            <Form.Control type="text" onChange={handleTokenChange} value={token} required/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="password" id="password_group">
                                            <Form.Label>Nowe hasło:</Form.Label>
                                            <Form.Control type="password" onChange={handlePasswordChange} value={password} required/>
                                        </Form.Group>
                                        <Errors>{errors.active ? errors.message : ''}</Errors>
                                        <Message>{message.active ? message.message : ''}</Message>
                                        <button className='btn btn-primary w-100 my-2' type='submit'>Wyślij</button>
                                        <button className='btn btn-secondary w-100 my-2' onClick={event =>  window.location.href='/login'} type='submit'>Logowanie</button>
                                    </Form>
                                    }
                                    {!show &&
                                    <Form>
                                        <Message>{message.active ? message.message : ''}</Message>                                
                                        <button className='btn btn-secondary w-100 my-2' onClick={event =>  window.location.href='/login'} type='submit'>
                                            Logowanie        
                                            <Countdown date={Date.now() + 5000} renderer={renderer}/> 
                                        </button>
                                    </Form>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </FluidLoginContainer>
    )
}

export default LoginPasswordConfirm