import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {User} from '../../../context/userContext';
import NotFound from '../../Errors/NotFound/NotFound';
import moment from 'moment/moment';
import AddTaskModal from './AddTaskModal/AddTaskModal';
import AddManyTaskModal from './AddTaskModal/AddManyTaskModal';

import GoBack from '../../Utils/GoBack/GoBack';
import ReportList from './ReportList/ReportList';
import useAdmin from '../../../hooks/useAdmin';

const SingleLecture = () => {
    const routeID = useParams();
    const [user] = useContext(User);
    const [lecture, setLecture] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [taskEdit, setTaskEdit] = useState(null);
    const [error, setError] = useState(false);
    let isTeacher = useAdmin();

    const [modalShow, setModalShow] = useState(false);
    const [modalName, setModalName] = useState("false");
    const [modalShowMany, setModalShowMany] = useState(false);

    const handleAddClick = () => {
        setModalName("Nowe zadanie");
        setTaskEdit(false);
        setModalShow(true);
    }

    const handleUpdateClick = (task) => {
        setModalName("Edytuj zadanie");
        setTaskEdit(task);
        setModalShow(true);
    }

    const handleAddManyClick = () => {
        setModalName("Dodaj wiele zadań");
        setModalShowMany(true);
    }

    const fetchLecture = async () => {
        fetch(`${process.env.REACT_APP_HOST}/api/lectures/${routeID.id}/`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).then(data => {
            console.log("fetchLecture")
            // console.log(data);
            setLecture(data);
        }).catch((error) => {
            console.error('Error:', error);
            setError(true);
        });
    }

    const fetchTasks = async () => {
        fetch(`${process.env.REACT_APP_HOST}/api/task/?lecture_id=${routeID.id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).then(data => {
            console.log("tasks")
            // console.log(data);
            setTasks(data);
        }).catch((error) => {
            console.error('Error:', error);
            setError(true);
        });
    }

    const handleDeleteTask = async (id) => {
        if (window.confirm('Czy na pewno chcesz usunąć to zadanie?')) {
            await fetch(`${process.env.REACT_APP_HOST}/api/task/${id}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + user?.token,
                    'Content-Type': 'application/json',
                }

            }).then(response => response).then(data => {
                // console.log(data);
                fetchLecture();
                fetchTasks();
            }).catch((error) => {
                console.error('Error:', error);
                alert('Wystąpił błąd podczas usuwania zadania!');
            });
        }
    }

    useEffect(() => {
        if (user?.logged) {
            fetchLecture();
            fetchTasks();
        }
    }, [user.logged]);


    return (
        <>
            {(() => {
                if (error) {
                    return <NotFound/>
                }

                if (!lecture)
                    return <></>

                return (
                    <>
                        <GoBack/>


                        <div className="container mt-5">
                            <div className="content">
                                <h4><i className="bi bi-calendar3"> </i>Przedmiot: {lecture.name ? lecture.name : ''}
                                </h4>
                                <h6><i
                                    className="bi bi-clock-fill"> </i>{lecture.description ? lecture.description : ''}
                                </h6>
                                {/*<form className="mt-4">*/}
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i
                                                className="bi bi-person-fill"></i> Prowadzący:</label>
                                            <input type="text" className="form-control" value={lecture.lecturer ?? ''}
                                                   disabled></input>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i
                                                className="bi bi-calendar-event-fill"></i> Data utworzenia:</label>
                                            <input type="text" className="form-control"
                                                   value={lecture.created ? moment(lecture.created).format("DD-MM-YYYY HH:mm") : '---'}
                                                   disabled></input>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label"><i
                                                className="bi bi-people-fill"></i> Uczestnicy:</label>
                                            <input type="text" className="form-control" value={lecture.members_count}
                                                   disabled>
                                            </input>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4><i className="bi bi-list-task"></i> Zadania:</h4>
                                    <div className="d-flex gap-2">
                                        {isTeacher && <>
                                            <button type="button" className="btn btn-outline-primary"
                                                    onClick={handleAddManyClick}><i
                                                className="bi bi-plus-circle"></i> Dodaj wiele
                                            </button>
                                            <button type="button" className="btn btn-outline-primary"
                                                    onClick={handleAddClick}><i
                                                className="bi bi-plus-circle"></i> Dodaj zadanie
                                            </button>
                                        </>}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped mt-3">
                                        <thead>
                                        <tr>
                                            <th><i className="bi bi-file-earmark-text-fill"></i> Nazwa zadania:</th>
                                            <th><i className="bi bi-calendar-range-fill"></i> Termin:</th>
                                            <th><i className="bi bi-clock-fill"></i> Utworzone:</th>
                                            <th><i className="bi bi-gear-fill"></i> Akcje</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {tasks && tasks.length > 0 && tasks.map((task, index) => {
                                            return (
                                                <tr key={index}>
                                                    {/*<td className='onMobile borderTopOnMobile mobileHeader'>Nazwa zadania:</td>*/}
                                                    <td>{task.name} </td>
                                                    {/*<td className='onMobile'>Termin:</td>*/}
                                                    <td>{moment(task.deadline).format("DD-MM-YYYY")}</td>
                                                    {/*<td className='onMobile'>Utworzone:</td>*/}
                                                    <td>{moment(task.created).format("DD-MM-YYYY HH:mm")}</td>
                                                    {isTeacher &&
                                                        <td className="">
                                                            <i className="bi bi-trash3-fill text-danger pointer m-2"
                                                               onClick={() => handleDeleteTask(task.id)}></i>
                                                            <i className="bi bi-pencil-fill text-warning pointer m-2"
                                                               onClick={() => handleUpdateClick(task)}></i>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })}

                                        </tbody>
                                    </table>
                                </div>


                                {isTeacher &&
                                    <ReportList tasks={tasks}/>
                                }

                            </div>
                        </div>

                        {modalShow && isTeacher ?
                            <AddTaskModal modalShow={modalShow} setModalShow={setModalShow} lectureId={routeID.id}
                                          fetchLecture={fetchLecture} fetchTasks={fetchTasks} modalName={modalName}
                                          taskEdit={taskEdit}/> : null}
                        {modalShowMany && isTeacher ?
                            <AddManyTaskModal modalShow={modalShowMany} setModalShow={setModalShowMany}
                                              lectureId={routeID.id} fetchLecture={fetchLecture} modalName={modalName}
                                              fetchTasks={fetchTasks}/> : null}

                    </>
                )
            })()}
        </>
    )
}

export default SingleLecture