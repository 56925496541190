import React, { useContext, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { User } from '../../../context/userContext';
import { useParams } from 'react-router';
import { upload } from '@testing-library/user-event/dist/upload';
import useAdmin from '../../../hooks/useAdmin';


const AddStudentsModal = (props) => {
    const [text, setText] = useState('');
    const [error, setError] = useState(null);
    const params = useParams();
    const [user] = useContext(User);
    let isTeacher = useAdmin();
    

  const fetchAddStudents = async () => {
    
    const persons = [];
    let lines = text.split("\n");
    lines.map(line => {
        let trios = line.split(",");
        var person = {
            index: trios[0],
            first_name: trios[1],
            last_name: trios[2]
          };
          persons.push(person)          
    })
    console.log(JSON.stringify(persons));


    await fetch(`${process.env.REACT_APP_HOST}/api/students/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        persons
    })
    })
    .then(res => res.json())
    .then(data => {
      props.handleOnUpload();
      handleClose();
    })
    .catch(err => {
        console.log(err);
    })
}


    const handleClose = () => {
        props.setModalShow(false);
    }

    const handleTextChange = (event) => {
        setText(event.target.value ?? '');
        setError(null);
    }
 
    const isActive = props.modalShow;

    const example_text = 
`973592,Adam,Bednarz
217354,Anna,Polka
973596,Roman,Nowicki
973597,Bogdan,Bosak
973598,Roman,Kucharz
973599,Paweł,Orzech
973600,Irena,Buczkowicz`
        

    return (
        <Modal show={isActive} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Rejestruj studentów</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Plik CSV:</Form.Label>
                        <Form.Control type="file" placeholder="Wybierz plik" onChange={handleFileChange}/>
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Dane:</Form.Label>
                        <Form.Control as="textarea" rows={10} placeholder={example_text} onChange={handleTextChange} value={text}/>

                    </Form.Group>
                    {error?.active && <p className='text-danger text-right'>{error.message}</p>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Zamknij
                </Button>
                <Button variant="primary" onClick={fetchAddStudents} >Wyślij</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddStudentsModal