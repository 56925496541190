import React, {useContext, useEffect, useState} from 'react'
import {renderButton, StyledLink} from './Lectures.styled'
import AddLectureModal from './AddModal/AddLectureModal'
import AddManyLecturesModal from './AddModal/AddManyLecturesModal'
import {saveAs} from "file-saver";
import {User} from '../../context/userContext'
import useAdmin from '../../hooks/useAdmin'

const Lectures = () => {
    const [user] = useContext(User);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowMany, setModalShowMany] = useState(false);

    const isTeacher = useAdmin();
    const [lectures, setLectures] = useState([]);
    const [lectureEdit, setLectureEdit] = useState(null);
    const [modalName, setModalName] = useState("");


    const handleLecturesFetch = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/lectures/`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).then(data => {
            setLectures(data);
        })
    }

    const handleAddClick = () => {
        setModalName("Nowe zajęcia");
        setLectureEdit(false);
        setModalShow(true);
    }

    const handleEditClick = (lecture) => {
        setModalName("Edytuj zajęcia");
        setLectureEdit(lecture);
        setModalShow(true);
    }

    const handleAddManyClick = () => {
        setModalName("Dodaj wiele zajęć");
        setModalShowMany(true);
    }

    const handleLectureDelete = async (id) => {
        if (window.confirm('Czy na pewno chcesz usunąć ten przedmiot?')) {
            await fetch(`${process.env.REACT_APP_HOST}/api/lectures/${id}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + user?.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                })
            }).then(response => response.json()).then(data => {
                console.log(data);
                handleLecturesFetch();
            }).catch((error) => {
                console.error('Error:', error);
                alert('Wystąpił błąd podczas usuwania przedmiotu!');
            });
        }
    }

    const handleLectureUpdate = async (id, enabled) => {
        await fetch(`${process.env.REACT_APP_HOST}/api/lectures/${id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
                enabled: enabled
            })
        }).then(response => response.json()).then(data => {
            console.log(data);
            handleLecturesFetch();
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas zmiany widoczności!');
        });

    }

    const handleLectureJoin = async (id) => {
        await fetch(`${process.env.REACT_APP_HOST}/api/user_lectures/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lecture: id
            })
        }).then(response => response.json()).then(data => {
            console.log(data);
            handleLecturesFetch();
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas dołączania!');
        });
    }

    // move to other view
    const handleUserLectureDelete = async (id) => {
        await fetch(`${process.env.REACT_APP_HOST}/api/user_lectures/${id}/`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lecture: id
            })
        }).then(response => response.json()).then(data => {
            console.log(data);
            handleLecturesFetch();
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas usuwania!');
        });
    }


    // download
    const handleDownload = async (id, mode = "zip") => {
        let suff = "";
        if (mode == "zip") {
            id ? suff = '/download_lecture_files/' + id + '/' : suff = '/download_all_files/';
        } else if (mode == "csv") {
            id ? suff = '/download_Lecture_report/' + id + '/' : suff = '/download_all_reports/';
            // download_Lecture_report
        } else {
            alert("Error! Plik błedny.");
            return;
        }


        await fetch(`${process.env.REACT_APP_HOST}/files${suff}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
                    console.log(response)
                    return response.blob();
                }
            )
            .then(function (blob) {
                console.log(blob)
                saveAs(blob, `download.${mode}`);
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('Wystąpił błąd podczas archiwizacji przedmiotu!');
            });
    }

    useEffect(() => {
        if (user.logged)
            handleLecturesFetch();
    }, [user.logged, modalShow, modalShowMany])

    return (
        <>

            <div className="container mt-5">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    {isTeacher &&
                        <div class="d-flex gap-2">
                            <button className="btn btn-info" onClick={handleAddManyClick}><i
                                className="bi bi-plus-circle-fill"></i> Dodaj wiele
                            </button>
                            <button className="btn btn-info" onClick={handleAddClick}><i
                                className="bi bi-plus-circle-fill"></i> Dodaj
                            </button>
                        </div>}
                </div>

                <div className="content">
                    <h4><i className="bi bi-journal-bookmark-fill my-2"></i> Dostępne zajęcia</h4>

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Nazwa przedmiotu</th>
                                <th>Prowadzący</th>
                                <th>Uczestnicy</th>
                                <th>Do oceny</th>
                                <th className="text-center">Akcje</th>
                            </tr>
                            </thead>

                            <tbody>
                            {lectures.map((lecture, index) => (
                                <tr key={lecture.id}>
                                    <td>{index + 1}</td>
                                    {lecture.enabled &&
                                        <td><StyledLink data-toggle="tooltip" data-placement="top"
                                                        title="Widoczne dla studentów" className="text-primary"
                                                        to={`/zajecia/${lecture.id}`}>{lecture.name}</StyledLink></td>}
                                    {!lecture.enabled &&
                                        <td><StyledLink data-toggle="tooltip" data-placement="top"
                                                        title="Niewidoczne dla studentów" className="text-dark"
                                                        to={`/zajecia/${lecture.id}`}><s>{lecture.name}</s></StyledLink>
                                        </td>}
                                    <td>{lecture.lecturer}</td>
                                    <td>{lecture.members_count}</td>
                                    <td>{lecture.unrated_reports_count}</td>
                                    <td className="text-center">
                                        <div className="d-flex justify-content-center w-100 flex-nowrap">
                                            <>
                                                {renderButton(!lecture.joined && !isTeacher, "primary", "Dołącz do grupy", "bi-person-plus-fill", () => handleLectureJoin(lecture.id))}
                                                {renderButton(lecture.joined && !isTeacher, "danger", "Opuść grupę", "bi-trash-fill", () => handleUserLectureDelete(lecture.id))}
                                                {renderButton(isTeacher && !lecture.enabled, "primary", "Publikuj grupę", "bi-eye", () => handleLectureUpdate(lecture.id, true))}
                                                {renderButton(isTeacher && lecture.enabled, "info", "Ukryj przedmiot", "bi-eye-slash", () => handleLectureUpdate(lecture.id, false))}
                                                {renderButton(isTeacher && !lecture.enabled, "success", "Pobierz ZIP", "bi-file-zip", () => handleDownload(lecture.id, "zip"))}
                                                {renderButton(isTeacher && !lecture.enabled, "success", "Pobierz CSV", "bi-filetype-csv", () => handleDownload(lecture.id, "csv"))}
                                                {renderButton(isTeacher, "warning", "Edytuj", "bi-pencil", () => handleEditClick(lecture))}
                                                {renderButton(isTeacher, "danger", "Usuń", "bi-trash3", () => handleLectureDelete(lecture.id))}
                                            </>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {isTeacher && lectures.length > 0 &&
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-center d-flex justify-content-center clearBorder">
                                        {renderButton(isTeacher, "success", "Pobierz wszystkie pliki jako ZIP", "bi-file-zip", () => handleDownload(0, "zip"))}
                                        {renderButton(isTeacher, "success", "Pobierz raport CSV", "bi-filetype-csv", () => handleDownload(0, "csv"))}
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {modalShow ? <AddLectureModal modalShow={modalShow}
                                          setModalShow={setModalShow}
                                          lectureEdit={lectureEdit}
                                          modalName={modalName}/> : null}
            {modalShowMany ?
                <AddManyLecturesModal modalShowMany={modalShowMany}
                                      setModalShowMany={setModalShowMany}
                                      modalName={modalName}/> : null}
        </>
    )
}

export default Lectures