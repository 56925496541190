import {createContext, useState} from 'react';
import UserConfig from './default/user';

export const User = createContext(UserConfig);

const UserContextProvider = (props) => {
    const [user, setUser] = useState(UserConfig);

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('reportData');
        localStorage.removeItem('user');

        setUser({
            logged: false,
            token: null,
            data: {}
        });
    };

    return (
        <User.Provider value={[user, setUser, logout]}>
            {props.children}
        </User.Provider>
    );
};

export default UserContextProvider;
