import moment from 'moment'
import React, {useContext} from 'react'
// import {DeadLine, StyledBadge, Wrapper} from './SingleReport.styled'
import {useNavigate} from 'react-router'
import {User} from '../../../../context/userContext'
import {DeadLine, StyledBadge, Wrapper} from "./ReportRow.styled";

const ReportRow = (props) => {
    const [user] = useContext(User);
    const navigator = useNavigate();

    if (props.empty)
        return (<tr className='tableEmpty'>
            <td colSpan={"100%"} className='borderTopOnMobile text-center'>Prowadzący nie zdefiniował sprawozdań</td>
        </tr>)

    const formatDeadline = (deadline) => {
        if (!deadline)
            return '-'
        return <DeadLine
            islate={!moment(deadline).isSameOrAfter(moment(), 'day') ? 1 : 0}>{moment(deadline).format('DD.MM.YYYY')}</DeadLine>
    }


    const handleOnClick = (tak_id) => {
        navigator(`/sprawozdania/${tak_id}/`)
    }

    return (
        <>
            {props?.lecture?.tasks?.map((task, index) => {
                    return (
                        <Wrapper key={index} onClick={() => handleOnClick(task?.id)}>
                            <td>{task.name}</td>
                            <td>{props?.lecture?.lecture_owner}</td>
                            <td className='text-center'>{formatDeadline(task.deadline)}</td>
                            {task.reports[0] && task.reports[0].grade && <td className='text-center'><StyledBadge
                                className="bg-info">Ocena {task.reports[0].grade}</StyledBadge></td>}
                            {task.reports[0] && !task.reports[0].grade && !task.reports[0].sent &&
                                <td className='text-center'><StyledBadge className="bg-warning">Rozpoczęto</StyledBadge>
                                </td>}
                            {task.reports[0] && !task.reports[0].grade && task.reports[0].sent &&
                                <td className='text-center'><StyledBadge className="bg-info">Oczekujące</StyledBadge></td>}
                            {!task.reports[0] &&
                                <td className='text-center'><StyledBadge className="bg-danger">Nowe</StyledBadge></td>}
                        </Wrapper>
                    )
                }
            )}
        </>
    )
}

export default ReportRow