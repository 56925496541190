import React, {useContext, useEffect, useState} from 'react'

import {Button, Form, Modal} from 'react-bootstrap';
import {User} from '../../../../context/userContext';
import moment from 'moment';

const AddTaskModal = (props) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [dueDate, setDueDate] = useState(moment().add(1, 'months').format('YYYY-MM-DD'));
    const [user] = useContext(User);


    const handleClose = () => {
        props.setModalShow(false);
    }

    const handleNameChange = (event) => {
        setName(event.target.value ?? '');
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value ?? '');
    }

    const handleDueDateChange = (event) => {
        setDueDate(event.target.value ? moment(event.target.value).format('YYYY-MM-DD') : moment().add(1, 'months').format('YYYY-MM-DD'));
    }


    const handleAddTaskRequest = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/task/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                description: description,
                lecture: props.lectureId,
                deadline: dueDate,
            })
        }).then(response => response.json()).then(data => {
            console.log(data);
            props.fetchLecture();
            props.fetchTasks();
            props.setModalShow(false);
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas dodawania zadania!');
        });
    }


    const handleUpdateTask = async (id) => {
        await fetch(`${process.env.REACT_APP_HOST}/api/task/${id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    name: name,
                    description: description,
                    deadline: dueDate,
                }),

        }).then(response => response).then(data => {
            console.log(data);
            props.fetchLecture();
            props.fetchTasks();
            props.setModalShow(false);
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas edytowania zadania!');
        });
    }

    useEffect(() => {
        if (user?.logged) {
            if (props.taskEdit) {
                setName(props.taskEdit.name);
                setDescription(props.taskEdit.description);
                setDueDate(moment(props.taskEdit.deadline).format('YYYY-MM-DD'));
            }
        }
    }, [user.logged]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        props.taskEdit ? handleUpdateTask(props.taskEdit.id) : handleAddTaskRequest();
    }

    const isActive = props.modalShow;

    return (
        <Modal show={isActive} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="bi bi-journal-plus"></i> {props.modalName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Nazwa:</Form.Label>
                        <Form.Control type="text" placeholder="Wprowadź nazwę zadania" onChange={handleNameChange}
                                      value={name} required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Opis:</Form.Label>
                        <Form.Control as="textarea" rows={6} placeholder='Wprowadź opis zadania'
                                      onChange={handleDescriptionChange} value={description}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Termin:</Form.Label>
                        <Form.Control type="date" onChange={handleDueDateChange} value={dueDate} required/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zamknij
                    </Button>
                    <Button variant="primary" type="submit">
                        Zapisz
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddTaskModal