import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react'
import { User } from '../../../../context/userContext';
import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import { UserWrapper, Username } from './ReportList.styled';
import { Badge, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ReportList = (props) => {      
    const [user] = useContext(User);
    const routeID = useParams();
    const navigator = useNavigate();
    const [members, setMembers] = useState([]);
    const [summary, setSummary] = useState([]);
    const [reports, setReports] = useState([]);


    const fetchSummary = async () => {
        fetch(`${process.env.REACT_APP_HOST}/api/lecture_summary/` + routeID.id + '/', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).then(data => {
            console.log("fetchSummary2");
            // console.log(data);
            setSummary(data);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const fetchMembers = async () => {
        fetch(`${process.env.REACT_APP_HOST}/api/user_lectures/?lecture_id=${routeID.id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).then(data => {
            console.log("setMembers");
            // console.log(data);
            setMembers(data);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }
    
    
    const getFloatGrade = (grade) => {
        if(!grade)
            return '---';
        return parseFloat(grade, 10).toFixed(1);
    }


    const getTaskStatus = (report) => {
        if (report===null)
            return <Badge variant="danger">Nie przysłano </Badge>       

        if(!report?.grade && report.sent)
                return <Badge className="bg-warning">Gotowe do oceny</Badge>
        else if (!report?.grade && !report.sent)
        return <Badge className="bg-secondary">Niegotowe do oceny</Badge>
        else                        {
            if(report?.grade == "2.0")
                return <Badge className="bg-danger">{getFloatGrade(report?.grade)}</Badge>
            return <Badge className="bg-success">{getFloatGrade(report?.grade)}</Badge>
        }      
    }

    const redirectToReport = (rep) => {
        if (rep)
            navigator(`/sprawozdania/${rep}`)
    }

    const getReport = (task, reports)=>{
        var res = null;
        reports.map(rep => {
            console.log(rep)
            if (task.id == rep.task)
                res = rep;
                return false; 
          })      
        return res
    }
    
    useEffect(() => {
        if (user?.logged)
            fetchMembers();
            fetchSummary();
    }, [user.logged]);

  return (
    <>
        {        
            summary?.members?.map((member, user_index) => {                
            return (
                <UserWrapper key={`user_${user_index}`}>

                    <h4 className="mt-5"><i className="bi bi-person-lines-fill"></i> Uczestnicy:</h4>
                    <div className="participant mb-4">
                        <h6><i className="bi bi-person-fill"></i> {member?.id} {member?.first_name} {member?.last_name} </h6>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Nazwa zadania:</th>
                                        <th className='text-center'>Status:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.tasks.map((task, task_index) => {
                                            return (
                                                <tr key={`report_${user_index}_${task_index}`} onClick={() => redirectToReport(getReport(task, member?.reports).id)} className='pointer'>
                                                        <td className="hideOnMobile" data-toggle="tooltip" data-placement="top" title={"Raport " + getReport(task, member?.reports)} >{task_index +1}</td>
                                                        {/*<td className='onMobile borderTopOnMobile mobileHeader'>#{task_index + 1} Nazwa zadania:</td>*/}
                                                        <td data-toggle="tooltip" data-placement="top" title={"Zadanie " + task.id} >{task.name}</td>
                                                        {/*<td className='onMobile'>Status:</td>*/}
                                                        <td className='text-center'>{getTaskStatus(getReport(task, member?.reports))}</td>
                                                </tr>
                                                )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </UserWrapper>
                )
            })            
        }    
    </>
  )
}

export default ReportList