import React, {useContext, useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap';
import {User} from '../../../context/userContext';

const AddLectureModal = (props) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [enabled, setEnabled] = useState(false);
    const [user] = useContext(User);

    const handleClose = () => {
        props.setModalShow(false);
    }

    const handleNameChange = (event) => {
        setName(event.target.value ?? '');
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value ?? '');
    }

    const handleEnabledChange = (event) => {
        setEnabled(!enabled);
    }

    const handleAddLecture = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/lectures/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                description: description,
                enabled: enabled.toString(),
                owner: user?.data?.user_id
            })
        }).then(response => response.json()).then(data => {
            console.log(data);
            props.setModalShow(false);
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas dodawania przedmiotu!');
        });
    }

    const handleUpdateLecture = async (id) => {
        await fetch(`${process.env.REACT_APP_HOST}/api/lectures/${id}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                description: description,
                enabled: enabled.toString(),
            })
        }).then(response => response.json()).then(data => {
            console.log(data);
            props.setModalShow(false);
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas dodawania przedmiotu!');
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        props.lectureEdit ? handleUpdateLecture(props.lectureEdit.id) : handleAddLecture();
    }

    useEffect(() => {
        if (user?.logged) {
            if (props.lectureEdit) {
                setName(props.lectureEdit.name);
                setDescription(props.lectureEdit.description);
                setEnabled(props.lectureEdit.enabled);
            }
        }
    }, [user.logged]);

    const isActive = props.modalShow;

    return (
        <Modal show={isActive} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><i className="bi bi-journal-plus"></i> {props.modalName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Nazwa:</Form.Label>
                        <Form.Control type="text" placeholder="Wprowadź nazwę przedmiotu" onChange={handleNameChange}
                                      value={name}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Opis:</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder='Wprowadź opis przedmiotu'
                                      onChange={handleDescriptionChange} value={description}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Check rows={3} label='Widoczne dla studentów' onChange={handleEnabledChange}
                                    checked={enabled}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Zamknij
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Zapisz
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddLectureModal