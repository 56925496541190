import React, {useContext, useEffect, useState} from 'react'
import {User} from '../../context/userContext'
import useAdmin from '../../hooks/useAdmin';
import userSuperAdmin from '../../hooks/useSuperAdmin'
import {Col, Row} from 'react-bootstrap';
import AddStudentsModal from './AddModal/AddStudentsModal'
import ClickableIndex from "../Common/ClickableIndex/ClickableIndex";
import ClickableEmail from "../Common/ClickableEmail/ClickableEmail";


const Users = () => {
    let [user] = useContext(User);
    let [students, setStudents] = useState([]);
    let isTeacher = useAdmin();
    let isSuperAdmin = userSuperAdmin();


    let [showAddStudentsModal, setShowAddFileModal] = useState(false);


    const fetchStudents = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/students/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                setStudents(data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleOnClick = (tak_id) => {
        navigator(`/student/${tak_id}/`)
    }

    const handleAddUser = () => {
        setShowAddFileModal(true);
    }


    useEffect(() => {
        if (user.logged) {
            fetchStudents();
        }

    }, [user.logged]);
    return (
        <>
            <div className="container mt-5">

                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4><i className="bi bi-list"></i> Lista Studentów</h4>
                    {isTeacher && isSuperAdmin && <button className="btn btn-info" onClick={handleAddUser}>
                        <i className="bi bi-person-plus-fill"></i> Dodaj użytkowników
                    </button>}
                </div>

                <div className="content">
                    <div className="table-responsive">
                        <table className="table table-striped mt-3">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Indeks:</th>
                                <th>Imię:</th>
                                <th>Nazwisko:</th>
                                <th>Email:</th>
                            </tr>
                            </thead>
                            <tbody>
                            {students?.map((student, index) =>
                                <tr>
                                    <td className='hideOnMobile'>{index + 1}</td>
                                    <td><ClickableIndex index={student.read_index}/></td>
                                    <td className='hideOnMobile'>{student.first_name}</td>
                                    <td className='hideOnMobile'>{student.last_name}</td>
                                    <td><ClickableEmail email={student.email}/></td>

                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>


                <Row>
                    <Col xs={12}>
                        {showAddStudentsModal &&
                            <AddStudentsModal modalShow={AddStudentsModal}
                                              setModalShow={setShowAddFileModal}
                                              handleOnUpload={fetchStudents}/>}

                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Users