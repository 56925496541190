import { Row } from 'react-bootstrap';
import styled from 'styled-components';



export const Wrapper = styled.div`
    margin-top: 80px;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
    max-width: 1320px;
`

export const Footer = styled(Row)`
    height: 100px;
`