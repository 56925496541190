import React from 'react';

const ClickableIndex = (props) => {
    const get_usos_url = (index) => {
        if (index)
            return "https://usos.prz.edu.pl/kontroler.php?_action=actionx%3Akatalog2%2Fosoby%2FszukajOsoby%28%29&_pattern=" + index;
        return index;
    }

    return (
        <>
            <a href={get_usos_url(props?.index)}>
                {props?.index}<i className="bi bi-box-arrow-in-up-right px-1"></i>
            </a>
        </>
    )
}

export default ClickableIndex;
