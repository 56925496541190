import React from 'react';

const ClickableEmail = (props) => {
    const get_usos_url = (email) => {
        if (email)
            return "mailto:" + email;
        return email;
    }

    return (
        <>
            <a href={get_usos_url(props?.email)}>
                <i className="bi bi-envelope-at p-1 no-undeline "></i>{props?.email}
            </a>
        </>
    )
}

export default ClickableEmail;
