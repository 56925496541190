import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import samplePDF from './sample.pdf';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import useAdmin from "../../../../hooks/useAdmin";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const PDFViewFile = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
 
    const isTeacher = useAdmin();
    function onLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
      }
    
      function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
      }
    
      function previousPage() {
        changePage(-1);
      }
    
      function nextPage() {
        changePage(1);
      }


    const viewUrl = (url) => {
      return `${process.env.REACT_APP_HOST}${url}`;
  }

    const googleUrlFile = (url) => {
      return `https://drive.google.com/viewerng/viewer?url=${url}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`;
  }

    const allowed = (url) => {
      let ALLOWED = ["PDF", "DOCX", "XLS"]
    }

    const isPDF = (url) => {
      return url.toLowerCase().endsWith('pdf');
    }


    return (
        <>
        <div className='pdfWrapper' >

            {props?.files?.map((file, index) => {
                    return (
                    <div>
                    {isPDF(file.document) &&
                    <>
                    <p>{index+1}. {file.document.split("/").pop()}:</p>
                    </> && <iframe src={file.document} width='100%' height='700px'></iframe>
                    }
                    </div> )
            })}
        </div>       
        </>
    )
}

export default PDFViewFile