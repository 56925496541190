import React, {useContext, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap';
import {User} from '../../../context/userContext';

const AddManyLecturesModal = (props) => {
    const [text, setText] = useState('');
    const [enabled, setEnabled] = useState(false);


    const [user] = useContext(User);

    const handleOpen = () => {
        props.setModalShowMany(true);
    }

    const handleClose = () => {
        props.setModalShowMany(false);
    }

    const handleTextChange = (event) => {
        setText(event.target.value ?? '');
    }

    const handleEnabledChange = (event) => {
        setEnabled(!enabled);
    }

    const handleAddLectures = async () => {
        const text2 = text.replace(/(?:\r\n|\r|\n)/g, ";")
        await fetch(`${process.env.REACT_APP_HOST}/api/text_to_lectures/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user?.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text: text2,
                enabled: enabled,
            })
        }).then(response => response.json()).then(data => {
            console.log(data);
            props.setModalShowMany(false);
        }).catch((error) => {
            console.error('Error:', error);
            alert('Wystąpił błąd podczas dodawania przedmiotów!');
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        handleAddLectures();
    }

    const example_text = `Wklej listę z USOS (wcięcia nie mają znaczenia), na przykład:
    Bezpieczeństwo hurtowni danych [EF/H-ZU>BHD] 
    Semestr letni 2022/23 
    Prowadzone grupy: 
    
        Laboratorium, grupa nr 1 
            każda niedziela, 12:00 - 13:45  
        Laboratorium, grupa nr 2 
            każda niedziela, 14:00 - 15:45`


    const isActive = props.modalShowMany;

    return (
        <Modal show={isActive} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><i className="bi bi-journal-plus"></i> {props.modalName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Treść:</Form.Label>
                        <Form.Control as="textarea" rows={10} placeholder={example_text} onChange={handleTextChange}
                                      value={text}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formVisiable">
                        <Form.Check rows={3} label='Widoczne dla studentów' onChange={handleEnabledChange}
                                    checked={enabled}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Zamknij
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Dodaj
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddManyLecturesModal