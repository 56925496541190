import React, {useContext} from 'react'
import AddCommentModal from './AddModal/AddCommentModal'
import moment from 'moment'
import useAdmin from '../../../../hooks/useAdmin'
import {User} from '../../../../context/userContext';

const Comments = (props) => {
    console.log(props)
    let isTeacher = useAdmin();
    let [user] = useContext(User);


    const formatDate = (date) => {
        if (!date)
            return '-'
        return moment(date).format('DD.MM.YYYY HH:mm');
    }


    const handleDeleteComment = async (id) => {
        console.log(props);
        if (window.confirm('Czy na pewno chcesz usunąć komentarz?')) {
            fetch(`${process.env.REACT_APP_HOST}/api/comments/${id}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + user?.token,
                    'Content-Type': 'application/json',
                },
            }).then(response => response).then(data => {
                console.log(data);
                props.fetchDetails();
            }).catch((error) => {
                console.error('Error:', error);
                props.handleOnUpload();
            });
        }
    }


    return (
        <>
            {props?.comments && props?.comments[0] &&
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Data:</th>
                            <th>Komenatarz:</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props?.comments?.map((comment, index) => {
                            return (
                                <tr key={index}>
                                    <td className='hideOnMobile'>{formatDate(comment.creation_date)}</td>
                                    <td>{comment.text}</td>
                                    {isTeacher &&
                                        <td className="text-center pointer"
                                            onClick={() => handleDeleteComment(comment.id)}>
                                            <i className="bi bi-trash3 text-danger pointer"></i></td>}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            }
            {!props?.comments || !props?.comments[0] && "brak."}

            {props?.showAddCommentModal &&
                <AddCommentModal modalShow={props.showAddCommentModal} setModalShow={props.setShowAddCommentModal}
                                 handleOnUpload={props.handleOnUpload}/>}
        </>
    )
}

export default Comments