import React, {useContext, useEffect, useState} from 'react'
import {User} from '../../context/userContext'
import {useNavigate} from 'react-router-dom'; // Importuj useNavigate
const Settings = () => {
    let [user] = useContext(User);
    let [settings, setSettings] = useState([]);
    let [apiKeyChatGpt, setApiKeyChatGpt] = useState('');
    let [apiKeyGptZero, setApiKeyGptZero] = useState('');
    let [errors, setErrors] = useState({});
    let [isApiKeyValid, setIsApiKeyValid] = useState(null); // Przechowywanie stanu poprawności klucza
    let [typingTimeout, setTypingTimeout] = useState(null); // Przechowywanie timeouta

    const navigate = useNavigate(); // Użycie hooka do nawigacji
    const fetchSettings = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/settings/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                setSettings(data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (user.logged) {
            fetchSettings();
        }

    }, [user.logged]);

    const checkApiKey = async (apiKey) => {
        try {
            const response = await fetch('https://api.openai.com/v1/models', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setErrors({}); // Klucz jest poprawny
                setIsApiKeyValid(true); // Ustaw poprawność klucza
            } else {
                setErrors({apiKeyChatGpt: "Niepoprawny klucz API Chat GPT."}); // Klucz jest niepoprawny
                setIsApiKeyValid(false); // Ustaw niepoprawność klucza
            }
        } catch (error) {
            setErrors({apiKeyChatGpt: "Błąd podczas weryfikacji klucza API."}); // Wystąpił błąd
            setIsApiKeyValid(false); // Ustaw niepoprawność klucza
        }
    };
    const handleApiKeyGptChange = (e) => {
        const value = e.target.value;
        setApiKeyChatGpt(value);

        // Anuluj poprzedni timeout
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        // Ustaw nowy timeout na 1 sekundę
        const newTimeout = setTimeout(() => {
            if (value) {
                checkApiKey(value);
            } else {
                setErrors({});
                setIsApiKeyValid(null); // Resetuj stan ikony
            }
        }, 1000); // Opóźnienie 1 sekundy

        setTypingTimeout(newTimeout); // Zapisz timeout w stanie
    };


    const validateForm = async () => {
        const newErrors = {};

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = async () => {
        console.log("Zapisany klucz API Chat GPT:", apiKeyChatGpt);
        navigate('/ustawienia'); // Przekieruj do strony głównej
    };


    return (
        <>
            <div className="container mt-5">
                <h4><i className="bi bi-gear-fill"></i> Ustawienia</h4>
                <div className="content mt-4">
                    <form>
                        <div className="mb-3 position-relative">
                            <label className="form-label" htmlFor="apiKeyChatGpt">API do Chatu GPT:</label>
                            <div className="input-group">
                                <input
                                    placeholder="Wprowadź klucz API"
                                    type="text"
                                    id="apiKeyChatGpt"
                                    className={`form-control ${errors.apiKeyChatGpt ? 'is-invalid' : ''}`}
                                    value={apiKeyChatGpt}
                                    onChange={handleApiKeyGptChange}
                                />
                                <span className="input-group-text">
                                    {isApiKeyValid === true && <i className="bi bi-emoji-smile text-success"></i>}
                                    {isApiKeyValid === false && <i className="bi bi-emoji-frown text-danger"></i>}
                                    {isApiKeyValid === null && <i className="bi bi-question-circle text-muted"></i>}
                                </span>
                            </div>
                            {errors.apiKeyChatGpt && <div className="invalid-feedback">{errors.apiKeyChatGpt}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="apiKeyGptZero">API do GPT Zero:</label>
                            <input
                                placeholder="Wprowadź klucz API"
                                type="text"
                                id="apiKeyGptZero"
                                className={`form-control ${errors.apiKeyGptZero ? 'is-invalid' : ''}`}
                                value={apiKeyGptZero}
                                onChange={(e) => setApiKeyGptZero(e.target.value)}
                            />
                            {errors.apiKeyGptZero && <div className="invalid-feedback">{errors.apiKeyGptZero}</div>}
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="checkbox" id="useSuggestions" className="form-check-input"/>
                                <label htmlFor="useSuggestions" className="form-check-label">Używaj podpowiedzi (Open
                                    AI)</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="checkbox" id="usePlagiarismDetection" className="form-check-input"/>
                                <label htmlFor="usePlagiarismDetection" className="form-check-label">Używaj detekcji
                                    plagiatu tekstu (Open AI)</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="checkbox" id="useChatGptDetection" className="form-check-input"/>
                                <label htmlFor="useChatGptDetection" className="form-check-label">Detekcja Chatu GPT w
                                    treści</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="checkbox" id="usePdfChatGptDetection" className="form-check-input"/>
                                <label htmlFor="usePdfChatGptDetection" className="form-check-label">Detekcja Chatu GPT
                                    w treści PDFów od studentów</label>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <button type="button" className="btn btn-secondary me-2" onClick={() => navigate('/')}>Anuluj
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>Zapisz</button>
                </div>
            </div>
        </>
    )
}
export default Settings